import AngusPhoto from '../../assets/images/angus_card_photo.JPEG';
import GavinPhoto from '../../assets/images/gav-sens-photo.jpg';

export const line_combination = {
  line_1: [
    {
      height: `6'2"`,
      hometown: 'Ottawa',
      name: 'Andrew Feniak',
      photo: null,
      position: 'LW',
    },
    {
      height: `5'10"`,
      hometown: 'Ottawa',
      name: 'Cody Hermann',
      photo: null,
      position: 'C',
    },
    {
      height: `6'0"`,
      hometown: 'Dar es Salaam',
      name: 'Mo Njau',
      photo: null,
      position: 'RW',
    },
  ],
  line_2: [
    {
      height: `5'11"`,
      hometown: 'Ottawa',
      name: 'Dom Heallis',
      photo: null,
      position: 'LW',
    },
    {
      height: `6'3"`,
      hometown: 'Ottawa',
      name: 'James Feniak',
      photo: null,
      position: 'C',
    },
    {
      height: `5'9"`,
      hometown: 'Ottawa',
      name: 'Gavin Tai',
      photo: GavinPhoto,
      position: 'RW',
    },
  ],
  line_3: [
    {
      height: `5'11"`,
      hometown: 'jiayuguan',
      name: 'Shawn Du',
      photo: null,
      position: 'LW',
    },
    {
      height: `5'9"`,
      hometown: 'Ottawa',
      name: 'Donald Sincennes',
      photo: null,
      position: 'C',
    },
    {
      height: `6'0"`,
      hometown: 'Ottawa',
      name: 'Angus Leung',
      photo: AngusPhoto,
      position: 'RW',
    },
  ],
  d1: [
    {
      height: `6'1"`,
      hometown: 'Ottawa',
      name: 'Mike Divins',
      photo: null,
      position: 'D',
    },
    {
      height: `6'0"`,
      hometown: 'Zhuhai',
      name: 'Keith Zhang',
      photo: null,
      position: 'D',
    },
  ],
  d2: [
    {
      height: `6'0"`,
      hometown: 'Ottawa',
      name: 'Dave Feniak',
      photo: null,
      position: 'D',
    },
    {
      height: `5'10"`,
      hometown: 'Ottawa',
      name: 'Brandon Crosby',
      photo: null,
      position: 'D',
    },
  ],
  g: [
    {
      height: `6'4"`,
      hometown: 'Ottawa',
      name: 'Kyle Freed',
      photo: null,
      position: 'G',
    },
  ],
};
